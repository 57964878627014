import { ChangeEvent, useState } from 'react';
import { useFirebaseContext } from '../hooks/useFirebaseContext';
import { DateTime } from 'luxon';

const questions = [
    `Of the 25 people we got demo info on, only 23 answered the crush questions, but we don't know how many answered the other two. So, when it says 96% use IG daily, is it correct to say it could be 96% of 23 respondents or 96% of 10 respondents? We aren’t provided the denominator for that percentage?`,
    "Where do I find my QR code?",
    "How do I make edits to SET.Live?",
    "Why would I use SET.Fan?",
    "How do I pick a winner?",
    "How do I add teammates?",
    "Is it possible to set up an enterprise account that houses all the different artist surveys we would run from set.live/set.fan?",
    "how do I link the set.live QR to a laylo drop page?",
    "Do you have a how to video or verbiage to set up a the giveaway ie how do I link the set.live QR and the message?",
    "How does the data flow?",
    "Where does the data go?",
    "Can I download as a CSV?",
    "How does the geofence work? How large of an area does it cover?",
    "How do we get brand deals?",
    "How do you use the data collected?",
    "Who owns the data?",
    "Do you work with Propeller? Can I raise funds for a charity?",
    "how long is the set.live page active?",
    "what are the best use-cases for set.fan?",
    "How many questions should we ask before there is a drop-off? What type of drop-off have you seen?",
    "Why are you free?",
  ];

export const Main = () => {

    const [threadId, setThreadId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const { 
        callable,
    } = useFirebaseContext();

    const submit = async () => {
        const el = document.getElementById('query') as HTMLInputElement;
        if (!el) {
            alert('Could not get input element');
            return;
        }

        setLoading(true);

        const start = DateTime.utc();

        const result = await fetch(
            'https://faq.ai.dev-set.rocks', 
            { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    q: el.value, 
                    threadId, 
                }),
            }
        )
        .then(res => res.json())
        .catch((err: any) => console.log(err));

        const end = DateTime.utc();

        setLoading(false);

        console.log('Got result', result);

        if (!result) {
            alert('Error getting response ' + result?.error?.message || '');
            return;
        }

        const responses = document.getElementById('response') as HTMLInputElement;

        responses.innerText = `${DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}\nRequest time: ${end.diff(start, ['seconds']).seconds} secs\nAI Response time: ${result?.duration} secs\n\nQ: ${el.value}\n\n${result?.text}\n\n==========================================\n\n${responses.innerText}`;

        setThreadId(result.threadId || '');
        
    }

    const select = (e: ChangeEvent) => {
        const s = document.getElementById('presaved') as HTMLSelectElement;
        const el = document.getElementById('query') as HTMLInputElement;
        if (s.value)
            el.value = s.options[s.selectedIndex].text;
    }

    return (
        <div style={{ margin: '20px auto', width: '80%' }}>
            <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bolder', marginBottom: '30px' }}>
                AI Demo
            </div>
            <div>
                <select id="presaved" onChange={select} style={{ width: '100%', overflow: 'hidden'}}>
                    <option value="">-- Select Pre-saved Question --</option>
                    {questions.map((q, index) => <option value={index}>{q}</option>)}
                </select>
            </div>
            <div>
                Enter your question:
            </div>
            <div>
                <textarea id="query" style={{ width: '100%', height: '100px', overflow: 'auto' }}/>
            </div>
            <div>
                { loading 
                    ?   <span>Processing...</span>
                    :   <button onClick={submit}>
                            Submit Question
                        </button>
                }
            </div>
            <div id="response" style={{ width: '100%', border: '1px solid', overflow: 'auto', height: '400px'}}/>
        </div>
    )
}
