import { FirebaseProvider } from "./providers/FirebaseProvider";
import { AppRoutes } from './routes';

function App() {
  return (
    <FirebaseProvider>
      <AppRoutes/>
    </FirebaseProvider>
  );
}

export default App;
